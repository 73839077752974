import React from 'react';
import { Grid, Typography, TextField, Link, IconButton, Button, Tooltip, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IntroLayout from './IntroLayout';
import LogoImg from '../components/LogoImg';
import { IUserAuthenticate } from '../models/create/IUserAuthenticate';
import { isValidEmail, isValidPassword, removeSpaces } from '../utils';
import { IUserAuthenticateResponse } from '../models/create/IUserAuthenticateResponse';
import { apiBaseURL } from '../globals';
import theme from '../theme';

type LoginProps = {
    redirectURL?: string,
};
type LoginState = {
    email: string,
    password: string,
    showPassword: boolean,
    incorrectLogin: boolean,
};

/**
 * Login page
 */
export default class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);

        this.state = {
            email: "",
            password: "",
            showPassword: false,
            incorrectLogin: false,
        };
    }    
    
    /**
     * Submits the user to the backend to be authenticated
     * 
     * @param event submit button click
     */
    handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isValidPassword(this.state.password) && isValidEmail(this.state.email)) {
            const loginUserBody: IUserAuthenticate = {
                email: this.state.email,
                password: this.state.password,
            }
    
            const response = await fetch(`${apiBaseURL}/users/authenticate`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginUserBody)
            });

            if (response.ok) {
                const userAuthenticateResponse: IUserAuthenticateResponse = await response.json();
    
                const token = userAuthenticateResponse.token;
            
                localStorage.setItem("token", token);
                
                // If the user entered the correct password, then the user is sent to the event home page
                window.location.href = (this.props.redirectURL ? this.props.redirectURL : "/");
            } else {
                // If the user's password was incorrect, alert the user to the error
                this.setState({incorrectLogin: true});
            }
        }
    }

    render() {
        return (
            <IntroLayout>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <Grid item>
                        <LogoImg/>
                    </Grid>

                    <Grid item>
                        <Typography align="center">
                            A tool for safely supporting connections between friends and family members during the pandemic
                        </Typography>
                    </Grid>

                    <form onSubmit={this.handleSubmit} autoComplete="off">
                        
                        <Grid item container spacing={2} direction="column" alignItems="center">
                            {this.state.incorrectLogin ? 
                            <Box p={2} bgcolor={theme.palette.error.main} boxShadow = {1} color={theme.palette.primary.light} m ={2} borderRadius={5}>
                                <Typography>Email or password was invalid</Typography>
                            </Box> : null
                            }
                            <Grid item>
                                <TextField label="Email" name="email" type="email"
                                    value={this.state.email}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({email: event.target.value})}
                                    required
                                    error={!isValidEmail(this.state.email) && (this.state.email !== "")}
                                />
                            </Grid>

                            <Grid item>
                                <Tooltip title={!isValidPassword(this.state.password) ? "Passwords must be 8 to 20 characters and contain at least 1 number, 1 uppercase letter, and 1 lowercase letter" : ""} disableHoverListener>
                                    <TextField
                                        label="Password" name="password"
                                        type={this.state.showPassword ? "text" : "password"}
                                        value={this.state.password}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({password: removeSpaces(event.target.value)})}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton size="small" onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                                    <FontAwesomeIcon icon={this.state.showPassword ? "eye-slash" : "eye"} fixedWidth/>
                                                </IconButton>
                                            ),
                                        }}
                                        required
                                        error={(!isValidPassword(this.state.password) && (this.state.password !== ""))}
                                    />
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Button type="submit">Log In</Button>
                            </Grid>
                        </Grid>
                    </form>
                    
                    <Grid item>
                        <Typography>
                            Or <Link href="/join">Create Account</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </IntroLayout>
        );
    }
}