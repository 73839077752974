import { Button, Typography } from '@material-ui/core';
import React from 'react';
import EventCard from '../components/EventCard';
import { Grid } from '@material-ui/core';
import Main from '../components/Main';
import { IEvent } from '../models/IEvent';
import { apiBaseURL } from '../globals';
import { convertToLocalTime, sortEventsByDate } from '../utils';
import Loading from './Loading';
import { IUser } from '../models/IUser';

// Event Cards
type EventHomeProps = {
    event: IEvent,
    eventId: string,
    index: number
}
type EventHomeState = {
    fetched: boolean,
    user?: IUser,
    createdEvents?: IEvent[],
    invitedEvents?: IEvent[],
}

/**
 * Main page where users can see all the events they have scheduled and been invited to
 */
export default class EventHome extends React.Component<EventHomeProps, EventHomeState> {
    constructor(props: EventHomeProps) {
        super(props);

        this.state = {
            fetched: false,
            user: undefined,
            createdEvents: undefined,
            invitedEvents: undefined,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    /**
     * Fetches all events associated with the currently logged-in user
     */
    async fetchData() {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            const [ eventsResponse, userResponse ] = await Promise.all([
                await fetch(`${apiBaseURL}/events`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
                await fetch(`${apiBaseURL}/users/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
            ]);
    
            if (eventsResponse.ok && userResponse.ok) {
                const [ events, user ] = await Promise.all([
                    await eventsResponse.json() as IEvent[],
                    await userResponse.json() as IUser
                ]);
    
                const createdEvents = [];
                const invitedEvents = [];
    
                for (const event of events) {
                    event.dateTime = new Date(event.dateTime);
                    event.dateTime = convertToLocalTime(event.dateTime);
    
                    if (event.creator.userID === user.userID) {
                        createdEvents.push(event);
                    } else {
                        invitedEvents.push(event);
                    }
                }
    
                this.setState({
                    fetched: true,
                    user: user,
                    createdEvents: sortEventsByDate(createdEvents),
                    invitedEvents: sortEventsByDate(invitedEvents),
                });
            } else {
                this.setState({
                    fetched: true,
                    user: undefined,
                    createdEvents: undefined,
                    invitedEvents: undefined,
                });
            }
        }
    }

    render () {
        const eventHomeThis = this;

        return (
            <Main>
                <Grid container spacing={4}>
                    <Grid item container alignItems="center">
                        <Grid item style={{flexGrow: 2}}>
                            <Typography variant = "h2">
                                Welcome Back{this.state.user ? ", " + this.state.user.firstName : null}!
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Button href="/create-event">Create Event +</Button>
                        </Grid>
                    </Grid>

                    <Grid item container direction="row" spacing={6}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" paragraph>
                                Your Events
                            </Typography>

                            <Grid container direction="column" spacing={2}>
                                {
                                    (this.state.createdEvents && (this.state.createdEvents.length > 0)) ?
                                        this.state.createdEvents.map((event: IEvent, index: number) =>(
                                            <Grid item key={index}>
                                                <EventCard event={event} user={eventHomeThis.state.user}/>
                                            </Grid>
                                        ))
                                    : (this.state.fetched ? <Grid item><Typography>You currently have no events scheduled.</Typography></Grid>
                                    : null)
                                }
                            </Grid>
                            
                        </Grid>
                        <Grid item xs = {12} md={6}>
                            <Grid item>
                                <Typography variant="h4" paragraph>
                                    Events You Are Invited To
                                </Typography>

                                <Grid container direction="column" spacing={2}>
                                    {
                                        (this.state.invitedEvents && this.state.invitedEvents.length > 0) ?
                                            this.state.invitedEvents.map((event: IEvent, index: number) =>(
                                                <Grid item key={index}>
                                                    <EventCard event={event} user={eventHomeThis.state.user}/>
                                                </Grid>
                                            ))
                                        : (this.state.fetched ? <Grid item><Typography>You have not been invited to any events.</Typography></Grid>
                                        : null)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {!this.state.fetched ? <Loading/> : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Main>
        );
    }
}