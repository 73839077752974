import { Box, Container } from '@material-ui/core';
import React, { ReactNode } from 'react';

type MainProps = {
    children: React.ReactElement | React.ReactElement[] | ReactNode[] | JSX.Element[] | JSX.Element
}

/**
 * Container for a page's main content (to take care of spacing)
 */
export default class Main extends React.Component<MainProps, {}> {
    render() {
        return (
            <Box my={4} mx={2}>
                <Container>
                    {this.props.children}
                </Container>
            </Box>
        );
    }    
}