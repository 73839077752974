import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import Login from './Login';
import Navbar from '../components/Navbar';

interface ProtectedRouteProps extends RouteProps {
    needsAuthentication: boolean,
}
interface ProtectedRouteState {
    isAuthenticated: boolean,
}

/**
 * Route component that determines how to route the user based on whether they are authenticated
 * to the application
 */
export default class ProtectedRoute extends React.Component<ProtectedRouteProps, ProtectedRouteState> {
    constructor(props: ProtectedRouteProps) {
        super(props);

        this.state = {
            isAuthenticated: (localStorage.getItem("token") !== null),
        }
    }

    render() {
        if (this.state.isAuthenticated === this.props.needsAuthentication) {
            // If the user's authentication status matches the needed authentication status, route to the requested page
            return (
                <React.Fragment>
                    {this.state.isAuthenticated ? <Navbar /> : null}
                    <Route {...this.props}>
                        {this.props.children}
                    </Route>
                </React.Fragment>
            );
        } else {
            if (this.props.needsAuthentication) {
                /**
                 * If the user is not authenticated but needs to be, show the login page on the requested route
                 * (so that when the user logs in, they will be shown the correct component for the route they were on
                 * rather than being redirected to the home page)
                 */
                return (
                    <Route {...this.props} component={undefined} render={undefined}>
                        <Login redirectURL={this.props.location?.pathname} />
                    </Route>
                );
            } else {
                /**
                 * If the user is authenticated but needs to not be, redirect to the home page
                 * (This would happen if the user is logged in and tries to go to the login or
                 * create account pages)
                 */
                return (
                    <Redirect to="/events"/>
                );
            }
        }
    }
}