import React from 'react';
import ReactDOM from 'react-dom';
import './custom.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Make Font Awesome icons available to all files of the app
// Reference: https://fontawesome.com/how-to-use/on-the-web/using-with/react
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab);

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();