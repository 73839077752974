import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Grid, IconButton, TextField } from '@material-ui/core';
import React from 'react';
import { apiBaseURL } from '../globals';
import { IUserUpdatePassword } from '../models/create/IUserUpdatePassword';
import { isValidPassword, removeSpaces } from '../utils';

type ChangePasswordDialogState = {
    showPassword: boolean,
    password: string,
    newPassword: string,
    confirmNewPassword: string,
    incorrectPassword: boolean,
}

/**
 * Dialog box providing input fields and functionality for the user to change their password
 */
export default class ChangePasswordDialog extends React.Component<DialogProps, ChangePasswordDialogState> {
    constructor(props: DialogProps) {
        super(props);

        this.state = {
            showPassword: false,
            password: "",
            newPassword: "",
            confirmNewPassword: "",
            incorrectPassword: false,
        }
    }

    /**
     * Input validation for password fields
     * 
     * @returns whether the password can or cannot be changed
     */
    isValidPasswordChange = () => {
        return isValidPassword(this.state.password) && isValidPassword(this.state.newPassword) &&
            (this.state.password !== this.state.newPassword) && (this.state.newPassword === this.state.confirmNewPassword);
    }

    /**
     * Closes the password dialog box
     * 
     * @param event button click or submit button click
     */
    handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
        // Reset all fields
        this.setState({
            showPassword: false,
            password: "",
            newPassword: "",
            confirmNewPassword: "",
            incorrectPassword: false,
        });

        // Close the dialog box
        if (this.props.onClose) {
            this.props.onClose(event, "backdropClick");
        }
    }

    /**
     * Submits the password change to the backend to be updated
     * 
     * @param event submit button click
     */
    handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (this.isValidPasswordChange()) {
            const updateUserPassword: IUserUpdatePassword = {
                oldPassword: this.state.password,
                newPassword: this.state.newPassword,
            }

            const token = localStorage.getItem("token");

            // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
            if (token) {
                const response = await fetch(`${apiBaseURL}/users/update/password`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updateUserPassword)
                });

                if (response.ok) {
                    // If the user entered the correct password, then their password was changed, so close the dialog box
                    this.handleClose(event);
                } else {
                    // If the user's password was incorrect, keep the box open, and show an error
                    this.setState({incorrectPassword: true});
                }
            }
        }
    }

    render() {
        return(
            <Dialog {...this.props}>
                <DialogTitle>Change Your Password</DialogTitle>

                <form onSubmit={this.handleSubmit} autoComplete="off">
                    <DialogContent style={{overflow: "hidden"}}>
                        <Grid item container spacing={2} direction="column">
                            <Grid item>
                                <DialogContentText>
                                    Passwords must be 8 to 20 characters and contain at least 1 number, 1 uppercase letter, and 1 lowercase letter.
                                </DialogContentText>
                            </Grid>

                            <Grid item>
                                <TextField
                                    label="Current Password" name="password"
                                    type="password"
                                    value={this.state.password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({password: removeSpaces(event.target.value), incorrectPassword: false})}
                                    fullWidth
                                    error={this.state.incorrectPassword || (!isValidPassword(this.state.password) && (this.state.password !== ""))}
                                    helperText={this.state.incorrectPassword ? "That password is incorrect" : undefined}
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    label="New Password" name="newPassword"
                                    type={this.state.showPassword ? "text" : "password"}
                                    value={this.state.newPassword}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({newPassword: removeSpaces(event.target.value)})}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                                <FontAwesomeIcon icon={this.state.showPassword ? "eye-slash" : "eye"} fixedWidth/>
                                            </IconButton>
                                        ),
                                    }}
                                    error={!isValidPassword(this.state.newPassword) && (this.state.newPassword !== "")}
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    label="Confirm New Password" name="confirmNewPassword"
                                    type={this.state.showPassword ? "text" : "password"}
                                    value={this.state.confirmNewPassword}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.setState({confirmNewPassword: removeSpaces(event.target.value)})}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton size="small" onClick={() => this.setState({showPassword: !this.state.showPassword})}>
                                                <FontAwesomeIcon icon={this.state.showPassword ? "eye-slash" : "eye"} fixedWidth/>
                                            </IconButton>
                                        ),
                                    }}
                                    error={this.state.newPassword !== this.state.confirmNewPassword}
                                    helperText={this.state.newPassword !== this.state.confirmNewPassword ? "The passwords do not match" : null}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button color="secondary" onClick={this.handleClose}>Cancel</Button>
                        <Button disabled={!this.isValidPasswordChange()} type="submit">Change Password</Button>
                    </DialogActions>
                </form>
            </Dialog>
        )
    }
}