import React from 'react';
import IntroLayout from './IntroLayout';
import { Grid, Typography } from '@material-ui/core';
import LogoImg from '../components/LogoImg';
import QuestionnaireForm from '../components/QuestionnaireForm';
import { apiBaseURL } from '../globals';
import { IUser } from '../models/IUser';
import Loading from './Loading';
import { Redirect } from 'react-router';

type QuestionnaireProps = {}

type QuestionnaireState = {
    fetched: boolean,
    user?: IUser,
}

/**
 * Initial questionnaire page where the user fills out the questionnaire form during account creation
 */
export default class Questionnaire extends React.Component<QuestionnaireProps, QuestionnaireState> {
    constructor(props: QuestionnaireProps) {
        super(props);

        this.state = {
            fetched: false,
            user: undefined,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const token = localStorage.getItem("token");

        if (token) {
            const userResponse = await fetch(`${apiBaseURL}/users/me`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
    
            if (userResponse.ok) {
                const user: IUser = await userResponse.json();
        
                this.setState({
                    fetched: true,
                    user: user,
                });
            } else {
                this.setState({
                    fetched: true,
                    user: undefined,
                });
            }
        }
    }

    render() {
        if (this.state.fetched && this.state.user) {
            if (this.state.user.questionnaire.responses.length <= 0) {
                return (
                    <IntroLayout width={8}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item style={{textAlign: "center"}}>
                                <LogoImg/>
                            </Grid>
        
                            <Grid item style={{textAlign: "center"}}>
                                <Typography variant="h4" paragraph>Questionnaire</Typography>
                            </Grid>
        
                            <Grid item>
                                <Typography variant="h5">
                                    <b>Welcome!</b> Just a few more questions to get you started.
                                </Typography>
                            </Grid>
        
                            <Grid item>
                                <QuestionnaireForm isEditing={true} redirectAfterSubmit="/events" submitButtonText="Finish"/>
                            </Grid>
                        </Grid>
                    </IntroLayout>
                );
            } else {
                return (
                    <Redirect to="/events"/>
                );
            }
        } else {
            return (
                <Loading/>
            );
        }
    }
}