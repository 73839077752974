import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@material-ui/core';
import React from 'react';
import { apiBaseURL } from '../globals';
import { IEvent } from '../models/IEvent';

interface DeleteEventDialogProps extends DialogProps {
    eventToDelete: IEvent,
}

/**
 * Dialog box that asks the user for confirmation to delete an event and provides functionality
 * to delete an event
 */
export default class DeleteEventDialog extends React.Component<DeleteEventDialogProps, {}> {
    /**
     * Closes the delete event dialog box
     * 
     * @param event button click
     */
    handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Close the dialog box
        if (this.props.onClose) {
            this.props.onClose(event, "backdropClick");
        }
    }

    /**
     * Deletes the event, closes the dialog box, and redirects the user back to the home page
     * 
     * @param event button click
     */
    deleteEvent = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            // Delete the event from the backend
            await fetch(`${apiBaseURL}/events/${this.props.eventToDelete.eventID}/delete`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            // Close the dialog box
            this.handleClose(event);

            // Redirect to the events home page
            window.location.replace("/events");
        }
    }

    render() {
        let {eventToDelete, ...dialogProps} = this.props;

        return (
            <Dialog {...dialogProps}>
                <DialogTitle>Delete {eventToDelete.title}</DialogTitle>

                <DialogContent>
                    Are you sure you want to delete {eventToDelete.title}? This action cannot be undone.
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleClose}>No, go back</Button>
                    <Button onClick={this.deleteEvent} color="secondary">Yes, delete the event</Button>
                </DialogActions>
            </Dialog>
        );
    }
}