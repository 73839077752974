import React from 'react';
import { Box, Container } from '@material-ui/core';
import theme from '../theme';
import background from '../assets/BackgroundFiltered.jpg';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

type HeaderProps = {
    children: JSX.Element[] | JSX.Element;
}

const styles = {
    root: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    }
}

/**
 * Container for a page's header content (to take care of spacing)
 */
const Header = withStyles(styles)(
    class extends React.Component<HeaderProps & WithStyles<'root'>, {}> {
        render() {
            return (
                <Box p={3} className={this.props.classes.root}>
                    <Container>
                        {this.props.children}
                    </Container>
                </Box>
            );
        }
    }
)

export default Header;