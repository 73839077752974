import React from 'react';
import { Box, Container, Grid, GridSize } from '@material-ui/core';
import background from '../assets/welcome_background.jpg';
import theme from '../theme';

type IntroLayoutProps = {
    children: JSX.Element | JSX.Element[],
    width?: GridSize,
}

/**
 * Component for the default layout for all intro pages (login, create account, questionnaire, etc.).
 * This component already contains a grid, so any children can be grid items (don't need grid containers)
 */
export default class IntroLayout extends React.Component<IntroLayoutProps, {}> {
    render() {
        // Set the default width of the text area to half of the screen
        let width: GridSize = 6;
        if (this.props.width) {
            width = this.props.width;
        }

        return (
            <Box width="100%" minHeight="100vh" height="100%" style={{backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed'}}>
                <Grid container direction="row-reverse">
                    <Grid item container alignItems="center" xs={12} md={width}
                        style={{backgroundColor: theme.palette.grey[50], padding: "3% 10%", minHeight: "100vh", boxShadow: "20px 0px 60px " + theme.palette.text.primary}}
                    >
                        <Container>
                            {this.props.children}
                        </Container>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}