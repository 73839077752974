import React from 'react';
import Logo from './Logo';
import { AppBar, Toolbar, Grid, Box, Link, Typography, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import theme from '../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type NavbarProps = {};

type NavbarState = {
    menuAnchor: HTMLElement | null,
};

const styles = {
    root: {
        backgroundColor: theme.palette.grey[100],
    },
    navbarLink: {
        color: theme.palette.text.primary,
        transition: "all 0.2s",
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: "none",
            cursor: "pointer",
        }
    },
    navbarLogo: {
        '&:hover': {
            textDecoration: "none",
        }
    }
};

/**
 * Navigation bar for the application
 */
const Navbar = withStyles(styles)(
    class extends React.Component<NavbarProps & WithStyles<typeof styles>, NavbarState> {
        constructor(props: NavbarProps & WithStyles<typeof styles>) {
            super(props);

            this.state = {
                menuAnchor: null,
            }
        }

        /**
         * Opens the navigation bar's menu (viewable in place of the navigation bar tabs
         * on small screens)
         * 
         * @param event button click
         */
        openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
            this.setState({
                menuAnchor: event.currentTarget,
            });
        }

        /**
         * Closes the navigation bar's menu (viewable in place of the navigation bar tabs
         * on small screens)
         * 
         * @param event button click
         */
        closeMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
            this.setState({
                menuAnchor: null,
            })
        }

        /**
         * Logs the user out of the application
         */
        handleLogout = () => {
            localStorage.clear();

            window.location.href = "/";
        }

        render() {
            /**
             * Tabs to show in the navigation bar
             */
            const navbarTabs = [
                {label: "Events", link: "/events"},
                {label: "Profile", link: "/profile"},
                {label: "Log Out", onClick: this.handleLogout},
            ];

            return (
                <AppBar position="sticky" className={this.props.classes.root}>
                    <Toolbar>
                        <Box width="100%" p={1}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item style={{ flexGrow: 2 }}>
                                    <Link href="/events" className={this.props.classes.navbarLogo}><Logo/></Link>
                                </Grid>

                                <Hidden xsDown>
                                    {navbarTabs.map((navbarLink) => {
                                        return (
                                            <Grid item key={navbarLink.label}>
                                                <Typography>
                                                    <Link href={navbarLink.link} onClick={navbarLink.onClick} className={this.props.classes.navbarLink}>{navbarLink.label}</Link>
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </Hidden>

                                <Hidden smUp>
                                    <Grid item>
                                        <IconButton onClick={this.openMenu}>
                                            <FontAwesomeIcon icon="bars"/>
                                        </IconButton>

                                        <Menu open={this.state.menuAnchor !== null}
                                            anchorEl={this.state.menuAnchor}
                                            onClose={this.closeMenu}
                                            keepMounted
                                        >
                                            {navbarTabs.map((navbarLink) => {
                                                return (
                                                    <Link href={navbarLink.link} onClick={navbarLink.onClick} className={this.props.classes.navbarLink} key={navbarLink.label}>
                                                        <MenuItem>{navbarLink.label}</MenuItem>
                                                    </Link>
                                                );
                                            })}
                                        </Menu>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </Box>

                    </Toolbar>
                </AppBar>
            );
        }
    }
);

export default Navbar;