import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { formatOptionsType } from './globals';
import { IEvent } from './models/IEvent';
import { IEventType } from './models/IEventType';
import { IUser } from './models/IUser';

/**
 * Gets an icon representing an event type format
 * 
 * @param format the event format (indoor, outdoor, or virtual)
 * @returns an icon component representing the given format
 */
export const convertEventFormatToIcon = (format: formatOptionsType) => {
    let icon: IconName = "question-circle";
    
    if (format === "Outdoor") {
        icon = "tree";
    } else if (format === "Indoor") {
        icon = "home";
    } else if (format === "Virtual") {
        icon = "video";
    }

    return (
        <FontAwesomeIcon icon={icon} fixedWidth/>
    );
}

/**
 * Formats a Date object for display throughout the app
 * 
 * @param dateTime a Date object to be formatted
 * @returns the desired string representation of the given Date object
 */
export const formatDateTimeString = (dateTime: Date) => {
    return dateTime.toLocaleString([], {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit'
    });
}

/**
 * Converts a date and time from Universal Coordinated Time (UTC) to the user's
 * local time
 * 
 * @param dateTime a Date object (consisting of a date and time)
 * @returns the Date object offset according to the user's local time zone
 */
export const convertToLocalTime = (dateTime: Date) => {
    let localDate = new Date();
    let timezoneOffset = localDate.getTimezoneOffset();

    let convertedDate = new Date(dateTime);
    convertedDate.setMinutes(dateTime.getMinutes() - timezoneOffset);
    return convertedDate;
}

/**
 * Given a list of events, returns a copy of that list with events in chronological order
 * 
 * @param events a list of events
 * @returns the list of events sorted in chronological order
 */
export const sortEventsByDate = (events: IEvent[]) => {
    let copiedEvents = [...events];

    // Given events A and B, if A is later than B (its dateTime is greater), it should be sorted after B
    return copiedEvents.sort((a, b) => (a.dateTime >= b.dateTime) ? 1 : -1);
}

/**
 * Sorts a list of users by name, putting all undefined users at the end of
 * the list. If there are multiple undefined users, they are sorted by email.
 * 
 * @param users a list of users
 * @returns a sorted list of users
 */
export const sortUsersByName = (users: IUser[]) => {
    let copiedUsers = [...users];

    // Sort users alphabetically
    return copiedUsers.sort((a, b) => {
        if (a.firstName && b.firstName) {
            // If both first names exist, sort alphabetically
            return (a.firstName >= b.firstName) ? 1 : -1;
        } else if (a.firstName) {
            // If a's first name exists but b's first name is undefined, a goes first
            return -1;
        } else if (b.firstName) {
            // If b's first name exists but a's first name is undefined, b goes first
            return 1;
        } else {
            // If both are undefined, sort them by email
            return (a.email >= b.email) ? 1 : -1;
        }
    });
}

/**
 * Given an event format and an invitee invited to the event of that format, retrieves
 * the user's comfort score for that event format
 * 
 * @param userID the ID of the user whose event comfort score needs to be found
 * @param eventFormat the format of the event
 * @returns the user's comfort score for that event format (if available)
 */
export const getUserComfortScore = (userID: number, eventFormat: IEventType) => {
    // Find the user's comfort score in the event format's list of comfort scores
    let userComfortScore = eventFormat.scores.find(comfortScore => {
        return comfortScore.userID === userID;
    });

    // Return the user's score (if a valid user was found)
    return userComfortScore?.score;
}

/**
 * Determines whether the given user is the event creator of the given event
 * 
 * @param userID the ID of the user in question
 * @param event the event in question
 * @returns true if the given user is the creator of the event or false otherwise
 */
export const userIsEventCreator = (userID: number, event: IEvent) => {
    return userID === event.creator.userID;
}

/**
 * Determines whether the given user is an invitee of the given event
 * 
 * @param userID the ID of the user in question
 * @param event the event in question
 * @returns true if the given user is invited to the given event or false otherwise
 *          (note that this will return false if the user is the event's creator)
 */
export const userIsInvitee = (userID: number, event: IEvent) => {
    let inviteeIndex = event.invitees.findIndex((invitee) => {
        return invitee.userID === userID;
    })

    return inviteeIndex >= 0;
}

/**
 * Removes whitespace from text
 * 
 * @param text a string
 * @returns the string with all spaces removed
 */
export const removeSpaces = (text: string) => {
    return text.replace(/\s+/g, "");
}

/**
 * Determines if a password is valid. For a password to be valid, it must:
 * * contain 8 to 20 characters
 * * Include at least 1 number
 * * Not include whitespace
 * 
 * @param password the user's password attempt
 * @returns true if the password is valid, false otherwise
 */
export const isValidPassword = (password: string) => {
    // Check that all correct characters are present
    let characterRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/g;

    // Check that whitespace is present
    let whitespaceRegex: RegExp = /\s+/;

    return characterRegex.test(password) && !whitespaceRegex.test(password);
}

/**
 * Determines if an email is valid. Valid emails have an @ sign with valid characters on either side
 * 
 * @param email the user's email attempt
 * @returns true if the email is valid, false otherwise
 */
export const isValidEmail = (email: string) => {
    // Check that all correct characters are present
    let reg: RegExp = /^.+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    return reg.test(email);
}

/**
 * Determines if a given Date object represents a current or future date
 * 
 * @param dateTime a Date object (consisting of a date and time)
 * @returns true if the given date is on or after the current date, false otherwise
 */
export const isCurrentOrFutureDateTime = (dateTime: Date, compareOnlyDate?: boolean) => {
    // Get the current date
    let currentDate = new Date();
    
    // If comparing only the date and not the time, remove the time information from the date
    if (compareOnlyDate) {
        currentDate.setHours(0, 0, 0, 0);
    }

    // Determine if the given date is on or after the current date
    return dateTime >= currentDate;
}