import React from 'react';
import { convertEventFormatToIcon, getUserComfortScore, sortUsersByName } from '../utils';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Box, Tooltip, Button } from '@material-ui/core';
import { IEvent } from '../models/IEvent';
import { IEventType } from '../models/IEventType';
import UserDetails from './UserDetails';
import { apiBaseURL } from '../globals';
import { IEventUpdateType } from '../models/create/IEventUpdateType';

type EventAccordionProps = {
    event: IEvent,
    suggestions: IEventType[],
}

/**
 * An accordion summarizing an event's three best event format suggestions
 */
export default class EventAccordion extends React.Component<EventAccordionProps, {}> {
    /**
     * Updates the event type of an event
     * 
     * @param eventType the event type of the event
     */
    selectEventType = async (eventType: IEventType) => {
        const token = localStorage.getItem("token");

        const eventUpdateBody: IEventUpdateType = {
            eventID: this.props.event.eventID,
            selectedType: {
                eventTypeID: eventType.eventTypeID,
            },
        }

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            await fetch(`${apiBaseURL}/events/${this.props.event.eventID}/update/type`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(eventUpdateBody)
            });
        }

        // Redirect to event page
        window.location.href = `/event/${this.props.event.eventID}`;
    }

    render() {
        return (
            this.props.suggestions.map((suggestion, index) => {
                return (
                    <Accordion key={index}>
                        <AccordionSummary>
                            <Box width="100%" p={1}>
                                <Grid container alignItems="center">
                                    <Grid item container spacing={3} alignItems="center" xs={12} md={9}>
                                        <Grid item>
                                            <Tooltip title={suggestion.format + " Event"}>
                                                <Typography variant="h3" component="p">{convertEventFormatToIcon(suggestion.format)}</Typography>
                                            </Tooltip>
                                        </Grid>
    
                                        <Grid item>
                                            <Typography variant="h4" component="h2">{suggestion.name}</Typography>
                                        </Grid>
                                    </Grid>
    
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h5" component="p">
                                            <b>{Math.round(suggestion.overallScore)}%</b> Comfort Score
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            
                        </AccordionSummary>
    
                        <AccordionDetails>
                            <Box width="100%" p={3}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h5" component="h3">Event Creator</Typography>
                                        <UserDetails user={this.props.event.creator}
                                            score={getUserComfortScore(this.props.event.creator.userID, suggestion)} isCurrentUser={true}/>
    
                                        <Typography variant="h5" component="h3">Invitees</Typography>
                                        {sortUsersByName(this.props.event.invitees).map(invitee => {
                                            return (
                                                <UserDetails key={invitee.userID} user={invitee}
                                                    score={getUserComfortScore(invitee.userID, suggestion)}/>
                                            );
                                        })}
                                    </Grid>
    
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h5" component="h3">Comfort Score</Typography>
                                        <Typography>
                                            This suggestion matches attendees' comfort levels {Math.round(suggestion.overallScore)}% overall.
                                        </Typography>
                                    </Grid>
    
                                    <Grid item xs={12} style={{textAlign: "center"}}>
                                        <Button onClick={() => this.selectEventType(suggestion)}>
                                            Select Event
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                );
            })
        );
    }
}