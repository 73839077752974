/**
 * The base URL for server requests (change to http://localhost:5001 if running the
 * server locally)
 */
export const apiBaseURL = "https://api.togatherapp.org";

export const formatOptions = [ "Outdoor", "Indoor", "Virtual" ] as [ "Outdoor", "Indoor", "Virtual" ];
export type formatOptionsType = typeof formatOptions[number];

/**
 * The maximum number of top event format suggestions to show a user for an event
 */
export const numberOfSuggestionsToShow = 5;