import React from 'react';
import Main from '../components/Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import QuestionnaireForm from '../components/QuestionnaireForm';
import ChangePasswordDialog from '../components/ChangePasswordDialog';
import { IUser } from '../models/IUser';
import { apiBaseURL } from '../globals';
import Loading from './Loading';
import PageNotFound from './PageNotFound';

type ProfileProps = {
    match: {
        params: {
            userId: string,
        }
    }
}

type ProfileState = {
    user?: IUser,
    currentUserID?: number,
    fetched: boolean,
    isEditingQuestionnaire: boolean,
    isChangingPassword: boolean,
}

/**
 * Profile page
 */
export default class LoginProfile extends React.Component<ProfileProps, ProfileState> {
    constructor(props: ProfileProps) {
        super(props);

        this.state = {
            user: undefined,
            currentUserID: undefined,
            fetched: false,
            isEditingQuestionnaire: false,
            isChangingPassword: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    /**
     * Fetches the data of the user whose profile is being viewed
     */
    async fetchData() {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            const [ profileResponse, userResponse ] = await Promise.all([
                await fetch(`${apiBaseURL}/users/${this.props.match.params.userId ? this.props.match.params.userId : "me"}`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
                await fetch(`${apiBaseURL}/users/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
            ]);
    
            if (profileResponse.ok && userResponse.ok) {
                const [ profile, user ] = await Promise.all([
                    await profileResponse.json() as IUser,
                    await userResponse.json() as IUser
                ]);
        
                this.setState({
                    fetched: true,
                    user: profile,
                    currentUserID: user.userID,
                });
            } else {
                this.setState({
                    fetched: true,
                    user: undefined,
                });
            }
        }
    }

    render() {
        if (this.state.fetched) {
            if (this.state.user && this.state.user.firstName && this.state.user.lastName) {
                // If the user has been successfully fetched, show their profile information
                return (
                    <Main>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container direction="row" alignItems="center" spacing={3}>
                                    <Grid item>
                                        <FontAwesomeIcon icon="user-circle" size="10x"/>
                                    </Grid>
        
                                    <Grid item style={{flexGrow: 2}}>
                                        <Typography variant="h3">{this.state.user.firstName} {this.state.user.lastName}</Typography>
                                        <Typography>{this.state.user.email}</Typography>
                                    </Grid>
        
                                    {this.state.currentUserID === this.state.user.userID ?
                                        <Grid item>
                                            <Button onClick={() => this.setState({isChangingPassword: true})}>Change Password</Button>
                                            <ChangePasswordDialog open={this.state.isChangingPassword} onClose={() => this.setState({isChangingPassword: false})} fullWidth/>
                                        </Grid> : null
                                    }
                                </Grid>
                            </Grid>
        
                            <Grid item>
                                <Grid container spacing={2} direction="column">
                                    <Grid item container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Typography variant="h4">Questionnaire Responses</Typography>
                                        </Grid>
                
                                        {this.state.currentUserID === this.state.user.userID ?
                                            <Grid item>
                                                <IconButton onClick={() => this.setState({isEditingQuestionnaire: true})} disabled={this.state.isEditingQuestionnaire} size="small">
                                                    <FontAwesomeIcon icon="pen"/>
                                                </IconButton>
                                            </Grid> : null
                                        }
                                    </Grid>
        
                                    <Grid item>
                                        {this.state.user.isSharingQuestionnaire || (this.state.currentUserID === this.state.user.userID) ?
                                            <QuestionnaireForm
                                                isEditing={this.state.isEditingQuestionnaire}
                                                questionnaire={this.state.user.questionnaire}
                                                submitButtonFunction={() => this.setState({isEditingQuestionnaire: false})} submitButtonText="Save Responses"
                                            /> :
                                            <Typography>This user's responses are hidden.</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Main>
                );
            } else {
                // If there is no user with this ID, warn the user
                return (
                    <PageNotFound/>
                );
            }
        } else {
            // If the user has not yet been fetched, show a loading screen
            return (
                <Loading/>
            );
        }
    }
}

