import React from 'react';

import { Typography, Grid, IconButton } from '@material-ui/core';
import EventAccordion from '../components/EventAccordion';
import Header from '../components/Header';
import Main from '../components/Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiBaseURL, numberOfSuggestionsToShow } from '../globals';
import { IEvent } from '../models/IEvent';
import { IEventType } from '../models/IEventType';
import { convertToLocalTime, formatDateTimeString } from '../utils';
import PageNotFound from './PageNotFound';
import Loading from './Loading';
import { IUser } from '../models/IUser';

type EventSuggestionsProps = {
    match: {
        params: {
            eventId: string,
        }
    }
}
type EventSuggestionsState = {
    fetched: boolean,
    event?: IEvent,
    suggestions?: IEventType[],
    currentUser?: IUser,
}

/**
 * Page that displays available suggestions for a specific event
 */
export default class EventSuggestions extends React.Component<EventSuggestionsProps, EventSuggestionsState> {
    constructor(props: EventSuggestionsProps) {
        super(props);

        this.state = {
            fetched: false,
            event: undefined,
            suggestions: undefined,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    /**
     * Fetches event data and suggestion data for that event
     */
    async fetchData() {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            const [ eventResponse, suggestionsResponse, userResponse ] = await Promise.all([
                await fetch(`${apiBaseURL}/events/${this.props.match.params.eventId}`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
                await fetch(
                    `${apiBaseURL}/events/${this.props.match.params.eventId}/suggestions/${numberOfSuggestionsToShow}`
                , {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
                await fetch(`${apiBaseURL}/users/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
            ]);
    
            if (eventResponse.ok && suggestionsResponse.ok && userResponse.ok) {
                const [ event, suggestions, user ] = await Promise.all([
                    await eventResponse.json() as IEvent,
                    await suggestionsResponse.json() as IEventType[],
                    await userResponse.json() as IUser,
                ]);
                event.dateTime = new Date(event.dateTime);
                event.dateTime = convertToLocalTime(event.dateTime);
    
                this.setState({
                    fetched: true,
                    event: event,
                    suggestions: suggestions,
                    currentUser: user,
                });
            } else {
                this.setState({
                    fetched: true,
                    event: undefined,
                    suggestions: undefined,
                    currentUser: undefined,
                });
            }
        }
    }

    render() {
        if (this.state.fetched) {
            if (this.state.event && (this.state.event.creator.userID === this.state.currentUser?.userID)) {
                return (
                    <React.Fragment>
                        <Header>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="h2">{this.state.event.title}</Typography>
                                </Grid>
        
                                <Grid item>
                                    <IconButton href={"/event/" + this.state.event.eventID + "/edit"}>
                                        <FontAwesomeIcon icon="pen"/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                            
                            <Typography>
                                {formatDateTimeString(this.state.event.dateTime)}
                            </Typography>
                        </Header>
                        
                        {
                            this.state.suggestions ? (
                                <Main>
                                    <EventAccordion event={this.state.event} suggestions={this.state.suggestions}/>
                                </Main>
                            ) : null
                        }
                    </React.Fragment>
                );
            } else {
                return (
                    <PageNotFound/>
                );
            }
        } else {
            return (
                <Loading/>
            );
        }
    }
}