import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React from 'react';
import theme from '../theme';

type LogoProps = {
    variant?: Variant,
}

/**
 * Component for the application's text logo
 */
export default class Logo extends React.Component<LogoProps, {}> {
    render() {
       return (
            <Typography variant={this.props.variant ? this.props.variant : "h4"} component="p" style={{fontWeight: "bold"}} color="textPrimary">
                To<span style={{color: theme.palette.primary.dark}}>Gather</span>
            </Typography>
       );
    }
}