import MomentUtils from '@date-io/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from 'moment';
import React from 'react';
import Main from '../components/Main';
import UserDetails from '../components/UserDetails';
import { apiBaseURL } from '../globals';
import { IEventCreate } from '../models/create/IEventCreate';
import { IEventUpdateInfo } from '../models/create/IEventUpdateInfo';
import { IEvent } from '../models/IEvent';
import { IUser } from '../models/IUser';
import { convertToLocalTime, isCurrentOrFutureDateTime, sortUsersByName } from '../utils';
import PageNotFound from './PageNotFound';
import { isValidEmail } from '../utils';
import { IEventUserCreate } from '../models/create/IEventUserCreate';
import Loading from './Loading';

type CreateEditEventProps = {
    match: {
        params: {
            eventId: string,
        }
    }
}
type CreateEditEventState = {
    fetched: boolean,
    invalidEvent: boolean,
    event: IEvent,
    inviteeSearch: string,
    invalidInviteeSearch: boolean,
    submitAttempt: boolean,
    currentUser?: IUser,
}


/**
 * Page for users to create and edit events
 */
export default class CreateEditEvent extends React.Component<CreateEditEventProps, CreateEditEventState> {

    constructor(props: CreateEditEventProps) {
        super(props);

        // Set the default event time to be the current date rounded to the next hour
        let dateTime = new Date();
        dateTime.setMinutes(dateTime.getMinutes() + 90, 0, 0);
        dateTime.setMinutes(0, 0, 0);

        // Create a blank default event (along with other state variables)
        this.state = {
            fetched: false,
            invalidEvent: false,
            event: {
                eventID: -1,
                title: "",
                description: "",
                dateTime: dateTime,
                creator: {
                    email: "",
                    userID: -1,
                    eventIDs: [],
                    questionnaire: {
                        responses: []
                    },
                    isSharingQuestionnaire: false,
                    isSharingComfortScores: false,
                },
                invitees: [],
            },
            inviteeSearch: "",
            invalidInviteeSearch: false,
            submitAttempt: false,
            currentUser: undefined,
        }
    }

    componentDidMount() {
        if (this.props.match.params.eventId) {
            // If editing an event, get the event data and the current user data
            this.fetchEventAndUserData();
        } else {
            // If creating an event, there is no event data to get, so get only the current user data
            this.fetchUserData();
        }
    }

    /**
     * Fetch the event's and current user's data to pre-fill the page's form fields
     */
    fetchEventAndUserData = async () => {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            const [ eventResponse, userResponse ] = await Promise.all([
                await fetch(`${apiBaseURL}/events/${this.props.match.params.eventId}`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
                await fetch(`${apiBaseURL}/users/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
            ]);
    
            if (eventResponse.ok && userResponse.ok) {
                const [ event, user ] = await Promise.all([
                    await eventResponse.json() as IEvent,
                    await userResponse.json() as IUser,
                ]);
                event.dateTime = new Date(event.dateTime);
                event.dateTime = convertToLocalTime(event.dateTime);
    
                this.setState({
                    fetched: true,
                    event: event,
                    invalidEvent: false,
                    currentUser: user,
                });
            } else {
                this.setState({
                    fetched: true,
                    invalidEvent: true,
                });
            }
        }
    }

    /**
     * Fetch the current user's data
     */
    fetchUserData = async () => {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            const userResponse = await fetch(`${apiBaseURL}/users/me`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
    
            if (userResponse.ok) {
                const user: IUser = await userResponse.json();
        
                this.setState({
                    fetched: true,
                    currentUser: user,
                });
            } else {
                this.setState({
                    fetched: true,
                });
            }
        }
    }

    /**
     * Deletes an invitee from the event invitee list
     * 
     * @param inviteeEmail the email of the invitee to delete
     */
    removeInvitee = (inviteeEmail: string) => {
        if (this.state.event.invitees.length > 0) {
            // Create a copy of the list of invitees
            let newInvitees = [...this.state.event.invitees];

            // Delete the specified invitee
            newInvitees.splice(newInvitees.findIndex(user => user.email === inviteeEmail), 1);

            // Set the state based on the new list of invitees
            this.setState({
                event: {
                    ...this.state.event,
                    invitees: newInvitees,
                }
            });
        }
    }

    /**
     * Determines if a user with the given email has already been added to the event
     * 
     * @param email the email to check in the event attendee list
     * @returns true if a user with that email has already been invited or false otherwise
     */
    eventContainsUserWithEmail = (email: string) => {
        if (this.state.currentUser?.email === email) {
            return true;
        } else {
            let invitee = this.state.event.invitees.find(user => user.email === email);
            return (invitee !== undefined);
        }
    }

    /**
     * Adds an invitee to the event invitee list (based on the value of the "add invitee" box)
     * if that invitee exists in the database (otherwise, the invitee would not be added, and a warning
     * is shown)
     */
    addInvitee = async () => {
        // If the invitee search box contains a valid email and the invitee has not yet been added to the event, search the database for that invitee
        if (isValidEmail(this.state.inviteeSearch) && !this.eventContainsUserWithEmail(this.state.inviteeSearch)) {
            const token = localStorage.getItem("token");

            // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
            if (token) {
                const fetchUserBody: IEventUserCreate = {
                    email: this.state.inviteeSearch
                }

                const userResponse = await fetch(`${apiBaseURL}/users/email`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(fetchUserBody)
                });

                let inviteeToAdd: IUser | undefined = undefined;
        
                // If a valid invitee was found and the invitee has not already been invited, add that invitee to the list of invitees
                if (userResponse.ok) {
                    inviteeToAdd = await userResponse.json();
                } else {
                    inviteeToAdd = {
                        email: fetchUserBody.email,
                        // Below not used
                        userID: -1,
                        eventIDs: [],
                        questionnaire: {
                            responses: []
                        },
                        isSharingQuestionnaire: false,
                        isSharingComfortScores: false,
                    }
                }
                
                // Should always exist but this makes TypeScript understand
                if (inviteeToAdd) {
                    // Create a copy of the event object
                    let newEvent = {...this.state.event};

                    // Add the invitee
                    newEvent.invitees.push(inviteeToAdd);

                    // Set the new event object to the state's event object (with the new invitee) and clear the search box
                    this.setState({
                        event: newEvent,
                        inviteeSearch: "",
                        invalidInviteeSearch: false,
                        submitAttempt: false,
                    });  
                }
            }
        } else {
            this.setState({
                invalidInviteeSearch: true,
            });
        }
    }

    /**
     * Helper function to add an invitee when the user clicks "enter" on the "add invitee" text field
     * 
     * @param event keyboard click
     */
    addInviteeOnEnterClick = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            // Prevent the entire form from being submitted
            event.preventDefault();

            // Add the invitee
            this.addInvitee();
        }
    }

    /**
     * Updates the "add invitee" searchbox
     * 
     * @param event text field change
     */
    updateInviteeSearchBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            inviteeSearch: event.target.value,
            invalidInviteeSearch: false,
        });
    }

    /**
     * Updates a text field of the event
     * 
     * @param event text field change
     */
    updateEventField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;

        this.setState({
            event: {
                ...this.state.event,
                [name]: value,
            }
        });
    }

    /**
     * Updates the event's date and/or time
     * 
     * @param newDate the event's new date/time
     */
    updateEventDateTime = (newDate: Moment | null) => {
        if (newDate) {
            this.setState({
                event: {
                    ...this.state.event,
                    dateTime: newDate.toDate(),
                }
            })
        }
    }

    /**
     * Submits the form to create or update the event
     * 
     * @param event form submission
     */
    handleSubmit = async(event: React.FormEvent<HTMLFormElement>) =>{
        event.preventDefault();       

        const token = localStorage.getItem("token");

        // If the user selected a valid date and the invitees list is not empty, schedule the event
        if (isCurrentOrFutureDateTime(this.state.event.dateTime) && (this.state.event.invitees.length > 0)) {
            if (this.props.match.params.eventId) {
                // If editing an event, tell the backend to update the event
                const updateEventBody: IEventUpdateInfo = {
                    eventID: this.state.event.eventID,
                    title: this.state.event.title,
                    description: this.state.event.description,
                    dateTime: this.state.event.dateTime,
                    invitees: this.state.event.invitees,
                };

                // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
                if (token) {
                    await fetch(`${apiBaseURL}/events/${this.state.event.eventID}/update/info`, {
                        method: "POST",
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(updateEventBody)
                    });
                }
            } else {
                // If creating an event, create it in the backend
                const createEventBody: IEventCreate = {
                    title: this.state.event.title,
                    description: this.state.event.description,
                    dateTime: this.state.event.dateTime,
                    creator: this.state.event.creator,
                    invitees: this.state.event.invitees,
                }
                
                // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
                if (token) {
                    const response = await fetch(`${apiBaseURL}/events`, {
                        method: "POST",
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(createEventBody)
                    });

                    if (response.ok) {
                        const event: IEvent = await response.json();

                        this.setState({
                            event: {
                                ...this.state.event,
                                eventID: event.eventID,
                            }
                        })
                    }
                }
            }
            
            window.location.href = `/event/${this.state.event.eventID}/suggestions`;
        } else {
            // If the event does not have any invitees, show the user an error
            this.setState({
                submitAttempt: true
            });
        }
    }

    render() {
        if (this.state.fetched) {
            /**
             * To view this page, the event must be valid. If the user is editing an existing event, they must
             * also be the creator of that event. If they are creating a new event, they can access this page regardless,
             * since they will be assigned as the creator of the event.
             */
             if (!this.state.invalidEvent && ((this.state.currentUser?.userID === this.state.event.creator.userID) || !this.props.match.params.eventId)) {
                return (
                    <Main>
                        <form onSubmit={this.handleSubmit} autoComplete="off">
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h4" paragraph>Event Details</Typography>

                                    <Grid container direction="column" spacing={3}>
                                        <Grid item>
                                            <TextField
                                                value={this.state.event.title} 
                                                label="Title" name="title"
                                                onChange={this.updateEventField}
                                                required
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item>
                                            <TextField
                                                value={this.state.event.description} label="Description" name="description"
                                                multiline rows={4} onChange={this.updateEventField} fullWidth required
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6">Date</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    value={this.state.event.dateTime} onChange={this.updateEventDateTime}
                                                    format="MM/DD/yyyy"
                                                    fullWidth
                                                    required
                                                    error={!isCurrentOrFutureDateTime(this.state.event.dateTime, true)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6">Time</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardTimePicker
                                                    value={this.state.event.dateTime} onChange={this.updateEventDateTime}
                                                    fullWidth
                                                    required
                                                    error={!isCurrentOrFutureDateTime(this.state.event.dateTime)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="h4" paragraph>Spread the Word</Typography>

                                    <Grid container direction="column" spacing={3} >
                                        <Grid item>
                                            <TextField
                                                label="Add Invitee Email"
                                                value={this.state.inviteeSearch}
                                                onChange={this.updateInviteeSearchBoxChange}
                                                onKeyDown={this.addInviteeOnEnterClick}
                                                helperText={this.state.invalidInviteeSearch ? "That user could not be added, or email address was incorrect" : null}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton aria-label="add invitee" size="small" onClick={() => this.addInvitee()}>
                                                            <FontAwesomeIcon icon="user-plus" fixedWidth/>
                                                        </IconButton>
                                                    ),
                                                }}
                                                fullWidth
                                                type = "email"
                                                error={this.state.submitAttempt || this.state.invalidInviteeSearch}
                                                />
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6">Invitees</Typography>
                                            {
                                                (this.state.event.invitees.length > 0) ?
                                                    sortUsersByName(this.state.event.invitees).map(invitee => {
                                                        return (
                                                            <Typography key={invitee.userID + invitee.email}>
                                                                <IconButton color="secondary" size="small" onClick={() => this.removeInvitee(invitee.email)}>
                                                                    <FontAwesomeIcon icon="minus-circle" fixedWidth/>
                                                                </IconButton>
                                                                <UserDetails user={invitee} noIcon inline/>
                                                            </Typography>
                                                        );
                                                    })
                                                    : <Typography>No invitees yet!</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
    
    
                            <Box pt={3}>
                                <Grid container justify="center" spacing={1}>
                                    <Grid item>
                                        <Button color="secondary" href={this.props.match.params.eventId ? `/event/${this.state.event.eventID}` : "/events"}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button type="submit">{this.props.match.params.eventId ? "Update Event" : "Create Event"}</Button>     
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Main>
                );
            } else {
                // If there is no event with this ID or the user is not allowed to edit this event, warn them
                return (
                    <PageNotFound/>
                );
            }
        } else {
            return <Loading/>;
        }
    }    
}