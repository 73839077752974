import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const palette = createPalette({
    primary: {
        light: "#DAF7F2",
        main: "#39D0D0",
        dark: "#229E9E",
    },
    secondary: {
        main: "#F34358",
    },
    text: {
        primary: "#3D3C38",
        secondary: "#909090",
        disabled: "#A0A0A0",
    },
    error: {
        main: "#F34358",
    },
    grey: {
        50: "#F8F8F8", // alternative background color (such as for the login, create event, and questionnaire pages)
        100: "#EFEFEF", // navbar color
    },
})

const theme = createMuiTheme({
    palette: palette,
    typography: {
        fontFamily: "Roboto, Helvetica, Arial",
        fontSize: 15,
    },
    spacing: 10,
    props: {
        MuiAppBar: {
            elevation: 0,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiButton: {
            variant: "contained",
            color: "primary",
        },
        MuiAccordionSummary: {
            expandIcon: <FontAwesomeIcon icon="angle-down"/>,
        },
        MuiTooltip: {
            arrow: true,
        },
        MuiTextField: {
            variant: "outlined",
        },
        MuiCheckbox: {
            color: "primary",
        },
        MuiRadio: {
            color: "primary",
        },
        MuiModal: {
            disableScrollLock: true,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
            },
        },
        MuiAccordionSummary: {
            root: {
                backgroundColor: palette.primary.light,
            }
        },
        MuiTextField: {
            root: {
                // To prevent issues with icons in text fields making the text field a different width
                // (For full width text fields, use the fullWidth property in <TextField>)
                minWidth: "280px",
            }
        },
        MuiLink: {
            root: {
                color: palette.primary.dark,
            }
        },
    }
});

export default theme;