import { Link, Typography } from '@material-ui/core';
import React from 'react';
import theme from '../theme';
import { IUser } from '../models/IUser';
import StatusIcon from './StatusIcon';

type UserDetailsProps = {
    user: IUser,
    isCurrentUser?: boolean,
    score?: number,
    spaceAfter?: boolean,
    noIcon?: boolean,
    inline?: boolean,
}

/**
 * Given a user, shows details about that user in the format `Cole Johnson (cole@team22.com)`, optionally
 * including a status icon for the user's comfort score for an event format
 * 
 * @param user the user whose information needs to be shown
 * @param noIcon whether a status icon should be shown alongside the user's information
 * @param score the user's comfort score
 * @param inline whether the `UserDetails` returned should be its own `<p>` element (if `inline` is false) or will be nested into another text element elsewhere
 * @param spaceAfter whether the `UserDetails` text should have some paragraph spacing below it (this will only have an effect if `inline` is false)
 */
export default class UserDetails extends React.Component<UserDetailsProps, {}> {
    render() {
        let userDetails = (
            <React.Fragment>
                {this.props.noIcon ? null :
                    <StatusIcon
                        score={this.props.score}
                        hasFilledQuestionnaire={this.props.user.questionnaire.responses.length > 0}
                        isSharingScore={this.props.user.isSharingComfortScores || this.props.isCurrentUser === true}
                    />
                }

                {this.props.noIcon ? null : " "}

                {this.props.user.firstName ? this.props.user.firstName : "Unregistered"} {(this.props.user.lastName ? this.props.user.lastName : "User") + " "}
                
                <span style={{color: theme.palette.text.secondary}}>
                    ({this.props.user.email})
                </span>
            </React.Fragment>
        );

        if (this.props.inline) {
            return userDetails;
        } else {
            return (
                <Link href={`/profile/${this.props.user.userID}`} style={{textDecoration: "none", color: theme.palette.text.primary}}>
                    <Typography paragraph={this.props.spaceAfter}>
                        {userDetails}
                    </Typography>
                </Link>
            );
        }
    }
}