import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import picture from '../assets/BackgroundFiltered.jpg';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { CardActionArea, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { IEvent } from '../models/IEvent';
import { formatDateTimeString, getUserComfortScore } from '../utils';
import { Link } from "react-router-dom";
import theme from '../theme';
import StatusIcon from './StatusIcon';
import { IUser } from '../models/IUser';

type EventCardProps = {
    event: IEvent;
    user?: IUser;
};

const styles = {
    root: {
        display: "flex",
        height: "9.5em",
        background: theme.palette.primary.light,
    },
    picture: {
        width: "20%",
        maxWidth: "8em",
        height: "100%",
    },
};

/**
 * Card displaying an overview of an event
 */
const EventCard = withStyles(styles)(
    class extends React.Component<EventCardProps & WithStyles<'root'> & WithStyles<'picture'>, {}> {
        render() {
            return (
                <CardActionArea component={Link} to = {"/event/" + this.props.event.eventID} >
                    <Card className={this.props.classes.root}>
                        <CardMedia
                            className={this.props.classes.picture}
                            image={picture}
                        />
                        <CardContent>
                            <Typography variant="h5" noWrap>
                                {this.props.event.title} {
                                    this.props.event.selectedType
                                    ? <StatusIcon score={
                                        this.props.user ? getUserComfortScore(this.props.user.userID, this.props.event.selectedType) : undefined
                                    }/>
                                    : null
                                }
                            </Typography>

                            <Typography>
                                {formatDateTimeString(this.props.event.dateTime)}
                            </Typography>

                            <Typography>
                                {this.props.event.invitees.length + 1} People
                            </Typography>

                            <Typography>
                                {this.props.event.selectedType ? `${this.props.event.selectedType.name.trim()} (${this.props.event.selectedType.format})` : "Location Not Yet Selected"}
                            </Typography>
                        </CardContent>
                    </Card>
                </CardActionArea>
            );
        }
    }
);

export default EventCard;