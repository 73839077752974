import React from 'react';
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@material-ui/core';
import { apiBaseURL } from '../globals';
import { IQuestionAnswer } from '../models/IQuestionAnswer';
import { IQuestionnaire } from '../models/IQuestionnaire';
import { IQuestionnaireQuestion } from '../models/IQuestionnaireQuestion';
import Loading from '../containers/Loading';
import { IQuestionAnswerCreate } from '../models/create/IQuestionAnswerCreate';
import { IUserUpdateQuestionnaire } from '../models/create/IUserUpdateQuestionnaire';

type QuestionnaireFormProps = {
    isEditing: boolean,
    questionnaire?: IQuestionnaire,
    redirectAfterSubmit?: string,
    submitButtonText?: string,
    submitButtonFunction?: () => void,
};

type QuestionnaireFormState = {
    fetched: boolean,
    questionnaireQuestions?: IQuestionnaireQuestion[],
    userResponses: IQuestionAnswer[],
};

/**
 * The form displaying questionnaire questions and answer options, as well as a submit button
 */
export default class QuestionnaireForm extends React.Component<QuestionnaireFormProps, QuestionnaireFormState> {
    constructor(props: QuestionnaireFormProps) {
        super(props);

        this.state = {
            fetched: false,
            questionnaireQuestions: undefined,
            userResponses: this.props.questionnaire ? this.props.questionnaire.responses : [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    /**
     * Fetches the questionnaire questions to display in the component
     */
    fetchData = async () => {
        const response = await fetch(`${apiBaseURL}/questionnaire`);
        
        if (response.ok) {
            const questionnaireQuestions: IQuestionnaireQuestion[] = await response.json();

            this.setState({
                fetched: true,
                questionnaireQuestions: questionnaireQuestions,
            });
        } else {
            this.setState({
                fetched: true,
                questionnaireQuestions: undefined,
            });
        }
    }

    /**
     * Returns the index of the user's response to the question of the given index
     * 
     * @param questionId the ID of the questionnaire question
     * @returns the index of the user's response to that question if available, null otherwise
     */
    getUserResponseIndexForQuestion = (questionId: number) => {
        let userResponse;

        // If user responses were provided, search them for the one matching the requested question ID
        if (this.state.userResponses) {
            userResponse = this.state.userResponses.find(response => {
                return response.questionID === questionId;
            });
        }

        if (userResponse) {
            return userResponse.responseIndex;
        } else {
            return null;
        }
    }

    /**
     * Updates the user's response to a question
     * 
     * @param newResponseID the ID of the new response chosen by the user
     * @param questionID the ID of the question for which the user is updating their response
     */
    updateUserResponse = (newResponseID: number, questionID: number) => {
        let response: IQuestionAnswer = {
            questionID: questionID,
            responseIndex: newResponseID,
        }

        if (this.state.userResponses) {
            // If the user has responses to some questions, check if they have a response to this specific question
            let indexOfOriginalResponse = this.state.userResponses.findIndex(userResponse => {
                return userResponse.questionID === questionID;
            });

            if (indexOfOriginalResponse >= 0) {
                // If the user previously had a response for this question, update it
                let newResponses = [...this.state.userResponses];
                newResponses[indexOfOriginalResponse] = response;
                this.setState({
                    userResponses: newResponses,
                });
            } else {
                // If the user did not previously have a response for this question, add one
                this.setState({
                    userResponses: [...this.state.userResponses, response],
                });
            }
        } else {
            // If the user does not have a response for any questions, initialize the user responses and add this response
            this.setState({
                userResponses: [response],
            });
        }
    }

    /**
     * Submits the questionnaire to the backend to be updated
     * 
     * @param event submit button click
     */
    handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (this.state.userResponses.length === this.state.questionnaireQuestions?.length) {
            const updateUserBody: IUserUpdateQuestionnaire = {
                questionnaire: {
                    responses: this.state.userResponses as IQuestionAnswerCreate[],
                }
            }
    
            const token = localStorage.getItem("token");
    
            // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
            if (token) {
                await fetch(`${apiBaseURL}/users/update/questionnaire`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(updateUserBody)
                });
    
                // If the submit button should have additional functionality, run it here
                if (this.props.submitButtonFunction) {
                    this.props.submitButtonFunction();
                }
                
                // If the submit button should redirect the user to a different page, redirect them
                if (this.props.redirectAfterSubmit) {
                    window.location.href = this.props.redirectAfterSubmit;
                }
            }
        }

    }

    render() {
        if (this.state.fetched && this.state.questionnaireQuestions) {
            // If the questionnaire questions have already been fetched, show the questionnaire form
            return (
                <form onSubmit={this.handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                        {this.state.questionnaireQuestions.map(question => {
                            return (
                                <Grid item key={question.questionID}>
                                    <FormControl>
                                        <FormLabel>
                                            <Typography color="textPrimary">
                                                <b>{question.questionText}</b>
                                            </Typography>
                                        </FormLabel>

                                        <RadioGroup
                                            name={"" + question.questionID} value={this.getUserResponseIndexForQuestion(question.questionID)}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.updateUserResponse(parseInt(event.target.value), question.questionID)}
                                        >
                                            {question.options.map((option, optionIndex) => (
                                                    <FormControlLabel
                                                        key={optionIndex} name={"" + optionIndex} value={optionIndex}
                                                        control={<Radio/>} label={option}
                                                        disabled={!this.props.isEditing}
                                                    />
                                                )
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            );
                        })}

                        <Grid item>
                            <Divider/>
                        </Grid>

                        <Grid item>
                            <Typography paragraph>
                                <b>Questionnaire Responses:</b> Your questionnaire responses will be used to calculate your comfort scores
                                for events that you create and are invited to. If you choose to let other users see your questionnaire responses,
                                the responses will be shown on your profile page.
                            </Typography>

                            <Typography paragraph>
                                <b>Event Comfort Scores:</b> If you choose to share your comfort scores with other event invitees,
                                the scores will be represented by icons for other event invitees to see. If you choose not to share,
                                other users will see a lock icon indicating that you are not publicly sharing your comfort score with them.
                                Regardless of your selection, your comfort scores will be used in calculating the overall comfort metric
                                for any event you are invited to, aggregated with all other invitees' scores.
                            </Typography>
                        </Grid>

                        {(this.props.isEditing) ?
                            <Grid item style={{textAlign: "center"}}>
                                <Tooltip title={this.state.userResponses.length !== this.state.questionnaireQuestions.length? "Please answer all questions" : ""}>
                                    <Box>
                                        <Button type="submit" disabled={this.state.userResponses.length !== this.state.questionnaireQuestions.length}>
                                            {this.props.submitButtonText ? this.props.submitButtonText : "Submit"}
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            : null
                        }
                    </Grid>
                </form>
            );
        } else {
            // If the questionnaire questions have not yet been fetched, show the loading page
            return (
                <Loading/>
            );
        }
    }
}