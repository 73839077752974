import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import Header from '../components/Header';
import Main from '../components/Main';

/**
 * A "404 error" screen to show the user when the route they have navigated to
 * is invalid or does not exist
 */
export default class PageNotFound extends React.Component<{}, {}> {
    render() {
        return (
            <React.Fragment>
                <Header>
                    <Typography variant="h2" align="center">
                        Oops!
                    </Typography>
                </Header>

                <Main>
                    <Grid container spacing={4} direction="column" alignItems="center">
                        <Grid item>
                            <Typography align="center" variant="h5" component="p">
                                The page you are looking for does not exist.
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Button href="/events">Go Back Home</Button>
                        </Grid>
                    </Grid>
                </Main>
            </React.Fragment>
        );
    }
}