import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import Main from '../components/Main';

/**
 * A loading screen to display to the user when data is being fetched
 */
export default class Loading extends React.Component<{}, {}> {
    render() {
        return (
            <Main>
                <Box pt={4}>
                    <Grid container spacing={3} direction="column" alignItems="center">
                        <Grid item>
                            <CircularProgress size="10rem"/>
                        </Grid>

                        <Grid item>
                            <Typography align="center" variant="h5">
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Main>
        );
    }
}