import React from 'react';

import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import EventHome from './containers/EventHome';
import Profile from './containers/Profile';
import Login from './containers/Login';
import CreateEditEvent from './containers/CreateEditEvent';
import EventView from './containers/EventView';
import EventSuggestions from './containers/EventSuggestions';
import CreateAccount from './containers/CreateAccount';
import Questionnaire from './containers/Questionnaire';
import { CssBaseline } from '@material-ui/core';
import theme from './theme';
import ProtectedRoute from './containers/ProtectedRoute';
import PageNotFound from './containers/PageNotFound';

/**
 * Main application
 */
export default class App extends React.Component<{}, {}> {
    render() {
        return (
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Switch>
                        <ProtectedRoute exact path="/" component={Login} needsAuthentication={false} />
                        <ProtectedRoute exact path="/join" component={CreateAccount} needsAuthentication={false}/>

                        <ProtectedRoute exact path="/join/questionnaire" component={Questionnaire} needsAuthentication={true}/>
                        <ProtectedRoute exact path='/events' component={EventHome} needsAuthentication={true} />
                        <ProtectedRoute exact path='/create-event' component={CreateEditEvent} needsAuthentication={true} />
                        <ProtectedRoute exact path='/event/:eventId/edit' component={CreateEditEvent} needsAuthentication={true} />
                        <ProtectedRoute exact path='/event/:eventId' component={EventView} needsAuthentication={true} />
                        <ProtectedRoute exact path='/event/:eventId/suggestions' component={EventSuggestions} needsAuthentication={true} />
                        <ProtectedRoute exact path='/profile/:userId' component={Profile} needsAuthentication={true} />
                        <ProtectedRoute exact path='/profile' component={Profile} needsAuthentication={true} />
                    
                        <Route path="*" component={PageNotFound}/>
                    </Switch>
                </ThemeProvider>
            </BrowserRouter>
        );
    }
}