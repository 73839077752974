import React from 'react';
import Logo from './Logo';
import MainLogo from '../assets/MainLogo.png';
import { Box } from '@material-ui/core';

/**
 * Component for the application logo (both the icon and the text)
 */
export default class LogoImg extends React.Component<{}, {}> {
    render() {
       return (
            <Box textAlign="center">
                <img src={MainLogo} width="180px" alt="ToGather Logo"/>
                <Logo variant="h3"/>
            </Box>
       );
    }
}