import React from 'react';
import { Typography, Grid, Box, IconButton, Button } from '@material-ui/core';
import Header from '../components/Header';
import Main from '../components/Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import theme from '../theme';
import { IEvent } from '../models/IEvent';
import { apiBaseURL } from '../globals';
import { convertEventFormatToIcon, convertToLocalTime, formatDateTimeString, getUserComfortScore, sortUsersByName, userIsEventCreator } from '../utils';
import UserDetails from '../components/UserDetails';
import PageNotFound from './PageNotFound';
import Loading from './Loading';
import StatusIcon from '../components/StatusIcon';
import DeleteEventDialog from '../components/DeleteEventDialog';
import { IUser } from '../models/IUser';

type EventViewProps = {
    match: {
        params: {
            eventId: string,
        }
    }
}
type EventViewState = {
    fetched: boolean,
    event?: IEvent,
    user?: IUser,
    currentUserIsEventCreator?: boolean,
    isDeletingEvent: boolean,
}

/**
 * Page that shows the details for a specific event
 */
export default class EventView extends React.Component<EventViewProps, EventViewState> {
    constructor(props: EventViewProps) {
        super(props);

        this.state = {
            fetched: false,
            event: undefined,
            user: undefined,
            currentUserIsEventCreator: undefined,
            isDeletingEvent: false,
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    /**
     * Fetches the data for an event
     */
    fetchData = async () => {
        const token = localStorage.getItem("token");

        // Token should always exist on this page due to ProtectedRoute, but this ensures TypeScript understands
        if (token) {
            const [ eventResponse, userResponse ] = await Promise.all([
                await fetch(`${apiBaseURL}/events/${this.props.match.params.eventId}`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }),
                await fetch(`${apiBaseURL}/users/me`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
            ]);
    
            if (eventResponse.ok && userResponse.ok) {
                const [ event, user ] = await Promise.all([
                    await eventResponse.json() as IEvent,
                    await userResponse.json() as IUser
                ]);
                
                event.dateTime = new Date(event.dateTime);
                event.dateTime = convertToLocalTime(event.dateTime);
                
                this.setState({
                    fetched: true,
                    event: event,
                    user: user,
                    currentUserIsEventCreator: userIsEventCreator(user.userID, event),
                });
            } else {
                this.setState({
                    fetched: true,
                    event: undefined,
                    user: undefined,
                });
            }
        }
    }

    render() {
        if (this.state.fetched) {
            if (this.state.event) {
                let safetyIcon: IconName = "question-circle";
                let safetyMessage: string = "Location Not Yet Selected";
                
                if (this.state.event.selectedType) {
                    if (this.state.event.selectedType.format === "Outdoor") {
                        safetyIcon = "ruler";
                        safetyMessage = "Masks When Not Socially Distanced";
                    } else if (this.state.event.selectedType.format === "Virtual") {
                        safetyIcon = "check-circle";
                        safetyMessage = "No Masks Necessary";
                    } else {
                        safetyIcon = "shield-virus";
                        safetyMessage = "Masks and Social Distancing";
                    } 
                }
    
                return (
                    <React.Fragment>
                        <Header>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="h2">{this.state.event.title}</Typography>
                                </Grid>
        
                                {this.state.currentUserIsEventCreator ?
                                    <Grid item>
                                        <IconButton href={"/event/" + this.state.event.eventID + "/edit"}>
                                            <FontAwesomeIcon icon="pen"/>
                                        </IconButton>
                                    </Grid> : null
                                }
                            </Grid>
                            
                            <Typography>
                                {this.state.event.selectedType ? this.state.event.selectedType.name.trim() : "Location Not Yet Selected"}, {formatDateTimeString(this.state.event.dateTime)}
                            </Typography>
                        </Header>
            
                        <Main>
                            <Grid container spacing={4} justify="center">
                                <Grid item style={{textAlign: "center"}} xs={6} sm={3} md={2}>
                                    <Typography variant="h2" component="p"><FontAwesomeIcon icon="user-friends" fixedWidth/></Typography>
                                    <Typography>{this.state.event.invitees.length + 1} People</Typography>
                                </Grid>

                                {this.state.event.selectedType ?
                                    <React.Fragment>                    
                                        <Grid item style={{textAlign: "center"}} xs={6} sm={3} md={2}>
                                            <Typography variant="h2" component="p">
                                                {
                                                    (this.state.event.selectedType) ?
                                                        convertEventFormatToIcon(this.state.event.selectedType.format)
                                                        : null
                                                }
                                            </Typography>
                                            <Typography>
                                                {
                                                    (this.state.event.selectedType) ?
                                                        (this.state.event.selectedType.format + " Event")
                                                        : null
                                                }
                                            </Typography>
                                        </Grid>

                                        <Grid item style={{textAlign: "center"}} xs={6} sm={3} md={2}>
                                            <Typography variant="h2" component="p"><FontAwesomeIcon icon={safetyIcon} fixedWidth/></Typography>
                                            <Typography>{safetyMessage}</Typography>
                                        </Grid>

                                        <Grid item style={{textAlign: "center"}} xs={6} sm={3} md={2}>
                                            {this.state.currentUserIsEventCreator ?
                                                <React.Fragment>
                                                    <Typography variant="h2" component="p">{Math.round(this.state.event.selectedType.overallScore)}%</Typography>
                                                    <Typography>Overall Comfort Score</Typography>
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    <Typography variant="h2" component="p">
                                                    <StatusIcon score={
                                                        this.state.user ? getUserComfortScore(this.state.user.userID, this.state.event.selectedType) : undefined
                                                    }/>
                                                    </Typography>
                                                    <Typography>Your Comfort Score</Typography>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </React.Fragment>
                                    : <Grid item style={{textAlign: "center"}} xs={6} sm={3} md={2}>
                                        <Typography variant="h2" component="p"><FontAwesomeIcon icon={safetyIcon} fixedWidth/></Typography>
                                        <Typography>{safetyMessage}</Typography>
                                    </Grid>
                                }

                                
                            </Grid>
    
                            <Grid container spacing={4} alignItems="stretch">
                                <Grid item xs={12} md={6}>
                                    <Box p={2} bgcolor={theme.palette.primary.light}>
                                        <Typography variant="h5" component="h3">Event Creator</Typography>
                                        <UserDetails
                                            user={this.state.event.creator}
                                            score={this.state.event.selectedType ? getUserComfortScore(this.state.event.creator.userID, this.state.event.selectedType) : undefined}
                                            isCurrentUser={this.state.user && this.state.user.userID === this.state.event.creator.userID}
                                            spaceAfter
                                        />
                                        
                                        <Typography variant="h5" component="h3">Invitees</Typography>
                                        {
                                            sortUsersByName(this.state.event.invitees).map((invitee) => {
                                                return (
                                                    <UserDetails
                                                        user={invitee}
                                                        score={this.state.event && this.state.event.selectedType ? getUserComfortScore(invitee.userID, this.state.event.selectedType) : undefined}
                                                        isCurrentUser={this.state.user && this.state.user.userID === invitee.userID}
                                                        key={invitee.userID}
                                                    />
                                                );
                                            })
                                        }
                                    </Box>
                                </Grid>
            
                                <Grid item xs={12} md={6}>
                                    <Box p={2} bgcolor={theme.palette.primary.light}>
                                        <Typography variant="h5" component="h3">Event Description</Typography>
                                        <Typography paragraph>
                                            {this.state.event.description}
                                        </Typography>
        
                                        <Typography variant="h5" component="h3">Location</Typography>
                                        <Typography>
                                            {this.state.event.selectedType ? this.state.event.selectedType.name.trim() : "Not yet selected"}
                                        </Typography>
                                    </Box>
                                </Grid>
            
                                {this.state.currentUserIsEventCreator ?
                                    <Grid item xs={12} container justify="center" spacing={1}>
                                        <Grid item>
                                            <Button href={"/event/" + this.state.event.eventID + "/suggestions"}>View Suggestions</Button>
                                        </Grid>

                                        <Grid item>
                                            <Button color="secondary" onClick={() => this.setState({isDeletingEvent: true})}>Delete Event</Button>
                                            
                                            <DeleteEventDialog eventToDelete={this.state.event} open={this.state.isDeletingEvent} onClose={() => this.setState({isDeletingEvent: false})}/>
                                        </Grid>
                                    </Grid> : null
                                }
                            </Grid>
                        </Main>
                    </React.Fragment>
                );
            } else {
                // If there is no event with this ID, warn the user
                return (
                    <PageNotFound/>
                );
            }
        } else {
            // If the event has not yet been fetched, show a loading screen
            return (
                <Loading/>
            );
        }
    }
}